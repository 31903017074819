* {
  box-sizing: border-box;
}

body {
  padding: 0;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
    Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  line-height: 1.8;
  font-size: 16px;
  /*color: rgb(156 163 175);*/
  /*background-color: rgb(17 17 17);*/
}

a {
  color: inherit;
  text-decoration: none;
}

a:hover,
a:focus,
a:active {
  text-decoration: underline;
}

ul,
ol {
  padding-left: 1em;
}

#__next {
  display: flex;
  padding: 10px;
  line-height: 1.8;
}
