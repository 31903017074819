.main {
  margin: auto;
  padding-top: 50px;
  width: 500px;
  max-width: 100vw;

  a {
    color: rgb(53 172 223);
  }

  h1,
  h2 {
    font-weight: 600;
  }

  h2 {
    font-size: 24px;
  }
}

.title {
  margin: 0;
  font-size: 2.6rem;
  cursor: pointer;
}

@supports (-webkit-background-clip: text) {
  .title {
    background-image: linear-gradient(to right, #ef4444, #3b82f6);
    -webkit-text-fill-color: transparent;
    -webkit-background-clip: text;
  }
}

.logo {
  width: 80px;
  height: 80px;
}

.featList {
  margin-left: 1em;

  h3 {
    margin: 0;
    font-size: inherit;
    font-weight: 500;
  }
}

@media (max-width: 414px) {
  .title {
    font-size: 2.1rem;
  }
  .logo {
    width: 100px;
    height: 100px;
  }
  .featList {
    font-size: 14px;
  }
}

.links {
  font-size: 14px;
  margin-top: 30px;
  overflow: auto;

  p {
    display: flex;
    align-items: center;
    margin-bottom: 0;
    margin-top: 3px;
  }

  p strong {
    display: inline-block;
    min-width: 70px;
  }

  &::-webkit-scrollbar {
    display: none;
  }
}

.screenshot {
  margin-top: 30px;
}

.screenshotItem {
  margin: auto;

  img {
    height: auto;
    max-width: 100%;
    display: block;
    background-color: #fff;
  }
}
